import Swiper from 'swiper/bundle';

export default class Carousel {
  constructor(element) {
    this.element = element;
    this.options = {
      slidesPerView: 1.5,
      spaceBetween: 30,
      centeredSlides: true,
      loop: false,
      loopedSlides: 3,
      effect: 'coverflow',
      coverflowEffect: {
        rotate: -20,
        stretch: 50,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      },
      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      centeredSlidesBounds: true,
      breakpoints: {
        375: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1366: {
          slidesPerView: 1.5,
          spaceBetween: 30,
        },
      },
    };
    this.init();
  }

  init() {
    this.setOptions();
    new Swiper(this.element, this.options);
  }

  setOptions() {
    this.variant = this.element.dataset.variant;
    console.log(this.variant);
    if (this.variant == 'loop') {
    }

    if ('autoplay' in this.element.dataset) {
      this.options.autoplay = {
        delay: 2500,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      };
    }

    if ('loop' in this.element.dataset) {
      this.options.loop = true;
    }
  }
}
