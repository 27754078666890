// Class definition for the Header
export default class Header {
  constructor(element) {
    this.element = element;
    this.html = document.documentElement;

    this.initNavMobile(); // Initialize mobile navigation functionality
  }

  initNavMobile() {
    const toggle = this.element.querySelector('.js-toggle'); // Find the toggle button
    toggle.addEventListener('click', this.onToggleNav.bind(this)); // Attach click event handler

    const contactLink = this.element.querySelector(
      '.nav-primary__item[href="#contact"]'
    );
    contactLink.addEventListener('click', this.onContactClick.bind(this)); // Attach click event handler for Contact link
  }

  onToggleNav() {
    this.html.classList.toggle('nav-is-active'); // Toggle the active class on the HTML element
  }

  onContactClick() {
    this.html.classList.remove('nav-is-active'); // Remove the active class to close the menu
  }
}
