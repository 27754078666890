export default class HoverText {
  constructor(element) {
    this.element = element;
    this.originalText = element.dataset.originalText;
    this.hoverText = element.dataset.hoverText;
    this.isAnimating = false;
    this.textContentElement = this.element.querySelector('.text-content');
    this.currentText = this.originalText;

    console.log('Initializing HoverText with originalText:', this.originalText);
    console.log('HoverText hoverText:', this.hoverText);

    this.init();
  }

  init() {
    this.setText(this.originalText);
    this.element.addEventListener('mouseenter', () => this.handleMouseEnter());
    this.element.addEventListener('mouseleave', () => this.handleMouseLeave());
    this.element.addEventListener('focus', () => this.handleMouseEnter());
    this.element.addEventListener('blur', () => this.handleMouseLeave());
    this.element.setAttribute('tabindex', '0');
  }

  async handleMouseEnter() {
    if (this.isAnimating || this.currentText === this.hoverText) return;
    this.isAnimating = true;
    await this.animateText(this.hoverText);
    this.isAnimating = false;
  }

  async handleMouseLeave() {
    if (this.isAnimating || this.currentText === this.originalText) return;
    this.isAnimating = true;
    await this.animateText(this.originalText);
    this.isAnimating = false;
  }

  setText(html) {
    this.textContentElement.innerHTML = html;
    this.currentText = html;
  }

  animateText(targetHTML) {
    return new Promise((resolve) => {
      const speed = 10;
      let index = 0;
      this.textContentElement.innerHTML = '';
      let openTags = [];
      const regex = /<\/?[^>]+>/g;

      const typeInterval = setInterval(() => {
        if (index < targetHTML.length) {
          const char = targetHTML.charAt(index);
          if (char === '<') {
            let tag = '';
            while (
              targetHTML.charAt(index) !== '>' &&
              index < targetHTML.length
            ) {
              tag += targetHTML.charAt(index);
              index++;
            }
            tag += targetHTML.charAt(index);
            index++;
            this.textContentElement.innerHTML += tag;

            const tagNameMatch = tag.match(/<\/?(\w+)/);
            if (tagNameMatch) {
              const tagName = tagNameMatch[1];
              if (!tag.startsWith('</')) {
                openTags.push(tagName);
              } else {
                openTags.pop();
              }
            }
          } else {
            this.textContentElement.innerHTML += char;
            index++;
          }
        } else {
          while (openTags.length > 0) {
            const tagName = openTags.pop();
            this.textContentElement.innerHTML += `</${tagName}>`;
          }
          clearInterval(typeInterval);
          this.currentText = targetHTML;
          resolve();
        }
      }, speed);
    });
  }
}
