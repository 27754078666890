import Scrolly from './components/Scrolly';
import Carousel from './components/Carousel';
import Header from './components/Header';
import Youtube from './components/Youtube';
import Form from './components/Form';
import ImageTabs from './components/ImageTabs';
import NameAnimation from './components/NameAnimation';
import VideoPlayer from './components/VideoPlayer';
import CompetencesAnimation from './components/ScrollTrigger';
import LetterWave from './components/LetterWave';
import LoadingScreen from './components/LoadingScreen';
import HoverText from './components/HoverText';
import HoverTextFollow from './components/HoverTextFollow';

// import CookiesConsent from './components/CookiesConsent';

export default class ComponentFactory {
  constructor() {
    this.componentList = {
      Scrolly,
      Carousel,
      Header,
      Youtube,
      Form,
      ImageTabs,
      NameAnimation,
      VideoPlayer,
      CompetencesAnimation,
      LetterWave,
      LoadingScreen,
      HoverText,
      HoverTextFollow,

      // CookiesConsent,
    };
    this.init();
  }

  init() {
    const components = document.querySelectorAll('[data-component]');

    for (let i = 0; i < components.length; i++) {
      const element = components[i];
      const componentName = element.dataset.component;

      if (this.componentList[componentName]) {
        new this.componentList[componentName](element);
      } else {
        console.log(`La composante ${componentName} n'existe pas!`);
      }
    }
  }
}
