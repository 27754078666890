export default class NameAnimation {
  constructor(element) {
    this.element = element;
    this.fred = this.element.querySelector('.fred');
    this.lag = this.element.querySelector('.lag');
    this.colors = [
      '--color-primary',
      '--color-secondary',
      '--color-tertiary',
      '--color-quaternary',
    ];

    this.fredI = this.fred.querySelector('span:nth-child(7)');
    this.lagL = this.lag.querySelector('span:nth-child(1)');

    this.isMobile = window.innerWidth <= 375;

    this.handleResize = this.handleResize.bind(this);

    this.init();
  }

  init() {
    this.setupObserver();
    if (this.isMobile) {
      this.startAutoColorChange();
      window.addEventListener('resize', this.handleResize);
    } else {
      this.addHoverListeners();
      window.addEventListener('resize', this.handleResize);
    }

    this.setInitialColor();
    this.startPeriodicColorChange();
  }

  setInitialColor() {
    const storedColor = localStorage.getItem('NameAnimationColor');
    let initialColor;

    if (storedColor && this.colors.includes(storedColor)) {
      initialColor = storedColor;
    } else {
      initialColor = this.getRandomColor();
      localStorage.setItem('NameAnimationColor', initialColor);
    }

    this.fredI.style.color = `var(${initialColor})`;
    this.lagL.style.color = `var(${initialColor})`;
  }

  handleResize() {
    const wasMobile = this.isMobile;
    this.isMobile = window.innerWidth <= 375;
    if (wasMobile !== this.isMobile) {
      if (this.isMobile) {
        this.removeHoverListeners();
        this.startAutoColorChange();
        this.startPeriodicColorChange();
      } else {
        this.stopAutoColorChange();
        this.addHoverListeners();
        this.startPeriodicColorChange();
      }
    }
  }

  setupObserver() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.fred.classList.add('is-inview');
            this.lag.classList.add('is-inview');
            observer.unobserve(this.element);
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(this.element);
  }

  addHoverListeners() {
    const letters = this.element.querySelectorAll('.fred span, .lag span');

    letters.forEach((letter) => {
      letter.style.cursor = 'pointer';
      letter.style.transition = 'color 0.5s ease';

      letter._timeoutID = null;

      letter.addEventListener(
        'mouseenter',
        this.handleMouseEnter.bind(this, letter)
      );
    });
  }

  removeHoverListeners() {
    const letters = this.element.querySelectorAll('.fred span, .lag span');

    letters.forEach((letter) => {
      letter.removeEventListener(
        'mouseenter',
        this.handleMouseEnter.bind(this, letter)
      );
      if (letter._timeoutID) {
        clearTimeout(letter._timeoutID);
        letter.style.color = 'var(--color-white)';
        letter._timeoutID = null;
      }
    });
  }

  handleMouseEnter(letter) {
    if (letter === this.fredI || letter === this.lagL) {
      this.changeColor([this.fredI, this.lagL]);
    } else {
      this.changeColor([letter]);
    }
  }

  getRandomColor() {
    const randomIndex = Math.floor(Math.random() * this.colors.length);
    return this.colors[randomIndex];
  }

  changeColor(letters) {
    if (letters.includes(this.fredI) && letters.includes(this.lagL)) {
      const newColor = this.getRandomColor();
      this.fredI.style.color = `var(${newColor})`;
      this.lagL.style.color = `var(${newColor})`;
      localStorage.setItem('NameAnimationColor', newColor);

      [this.fredI, this.lagL].forEach((letter) => {
        if (letter._timeoutID) {
          clearTimeout(letter._timeoutID);
        }

        letter._timeoutID = setTimeout(() => {
          letter.style.color = 'var(--color-white)';
          letter._timeoutID = null;
        }, 3000);
      });
    } else {
      letters.forEach((letter) => {
        if (letter._timeoutID) {
          clearTimeout(letter._timeoutID);
        }

        const newColor = this.getRandomColor();
        letter.style.color = `var(${newColor})`;

        letter._timeoutID = setTimeout(() => {
          letter.style.color = 'var(--color-white)';
          letter._timeoutID = null;
        }, 3000);
      });
    }
  }

  startAutoColorChange() {
    this.autoChangeColor();

    this.autoInterval = setInterval(() => {
      this.autoChangeColor();
    }, 3000);
  }

  autoChangeColor() {
    const newColor = this.getRandomColor();

    this.fredI.style.color = `var(${newColor})`;
    this.lagL.style.color = `var(${newColor})`;

    localStorage.setItem('NameAnimationColor', newColor);

    [this.fredI, this.lagL].forEach((letter) => {
      if (letter._timeoutID) {
        clearTimeout(letter._timeoutID);
      }

      letter._timeoutID = setTimeout(() => {
        letter.style.color = 'var(--color-white)';
        letter._timeoutID = null;
      }, 2000);
    });
  }

  stopAutoColorChange() {
    if (this.autoInterval) {
      clearInterval(this.autoInterval);
      this.autoInterval = null;
    }

    [this.fredI, this.lagL].forEach((letter) => {
      if (letter._timeoutID) {
        clearTimeout(letter._timeoutID);
        letter.style.color = 'var(--color-white)';
        letter._timeoutID = null;
      }
    });
  }

  startPeriodicColorChange() {
    const allLetters = this.element.querySelectorAll('.fred span, .lag span');
    const periodicIntervalTime = 5000;

    this.periodicInterval = setInterval(() => {
      allLetters.forEach((letter) => {
        const newColor = this.getRandomColor();
        letter.style.color = `var(${newColor})`;

        letter.style.transition = 'color 0.5s ease';

        setTimeout(() => {
          letter.style.color = 'var(--color-white)';
        }, 3000);
      });
    }, periodicIntervalTime);
  }

  stopPeriodicColorChange() {
    if (this.periodicInterval) {
      clearInterval(this.periodicInterval);
      this.periodicInterval = null;
    }

    const allLetters = this.element.querySelectorAll('.fred span, .lag span');
    allLetters.forEach((letter) => {
      letter.style.color = 'var(--color-white)';
      letter.style.transition = '';
    });
  }
}
