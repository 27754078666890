import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
console.log('ScrollTrigger registered');

export default class CompetencesAnimation {
  constructor(element) {
    console.log('CompetencesAnimation instantiated');
    this.element = element;
    this.containers = this.element.querySelectorAll('.competences__container');

    this.init();
  }

  init() {
    console.log('Initializing ScrollTrigger animations');
    this.containers.forEach((container) => {
      const content = container.querySelector('.competences__content');
      const text = container.querySelector('.competences__text');
      const h1 = text.querySelector('h1');
      const h5 = text.querySelector('h5');
      const logiciels = container.querySelector('.competences__logiciels');

      // Set initial state with autoAlpha for smoother transitions
      gsap.set(container, {
        backgroundColor: 'var(--color-black)',
        autoAlpha: 0,
      });
      gsap.set(content, {
        flexDirection: 'column',
        alignItems: 'center',
        autoAlpha: 0,
      });
      gsap.set(text, { textAlign: 'center', autoAlpha: 0 });
      gsap.set(h1, { color: 'var(--color-white)', x: 0, autoAlpha: 0 });
      gsap.set(h5, { autoAlpha: 0, maxHeight: 0 });
      gsap.set(logiciels, { autoAlpha: 0, x: 20 });

      ScrollTrigger.create({
        trigger: container,
        start: 'top 85%', // Adjusted for smoother entry
        end: 'bottom 15%',
        onEnter: () =>
          this.animateEnter(container, content, text, h1, h5, logiciels),
        onLeave: () =>
          this.animateLeave(container, content, text, h1, h5, logiciels),
        onEnterBack: () =>
          this.animateEnter(container, content, text, h1, h5, logiciels),
        onLeaveBack: () =>
          this.animateLeave(container, content, text, h1, h5, logiciels),
        markers: false, // Set to true if you need to debug
        scrub: true, // Adds smooth scrubbing
      });
    });

    // Refresh ScrollTrigger after any potential DOM changes
    ScrollTrigger.refresh();
  }

  animateEnter(container, content, text, h1, h5, logiciels) {
    gsap.to(container, {
      backgroundColor: 'var(--color-primary)',
      duration: 0.5,
      ease: 'power2.out',
      autoAlpha: 1,
    });
    gsap.to(content, {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 20px',
      duration: 0.5,
      ease: 'power2.out',
      autoAlpha: 1,
    });
    gsap.to(text, {
      textAlign: 'left',
      duration: 0.5,
      ease: 'power2.out',
      autoAlpha: 1,
    });
    gsap.to(h1, {
      color: 'var(--color-black)',
      x: 0,
      duration: 0.5,
      ease: 'power2.out',
      autoAlpha: 1,
    });
    gsap.to(h5, {
      maxHeight: '100px',
      duration: 0.5,
      ease: 'power2.out',
      autoAlpha: 1,
      delay: 0.1,
    });
    gsap.to(logiciels, {
      x: 0,
      duration: 0.5,
      ease: 'power2.out',
      autoAlpha: 1,
      delay: 0.2,
    });
  }

  animateLeave(container, content, text, h1, h5, logiciels) {
    gsap.to(container, {
      backgroundColor: 'var(--color-black)',
      duration: 0.5,
      ease: 'power2.in',
      autoAlpha: 0,
    });
    gsap.to(content, {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      duration: 0.5,
      ease: 'power2.in',
      autoAlpha: 0,
    });
    gsap.to(text, {
      textAlign: 'center',
      duration: 0.5,
      ease: 'power2.in',
      autoAlpha: 0,
    });
    gsap.to(h1, {
      color: 'var(--color-black)',
      x: 0,
      duration: 0.5,
      ease: 'power2.in',
      autoAlpha: 0,
    });
    gsap.to(h5, {
      maxHeight: 0,
      duration: 0.5,
      ease: 'power2.in',
      autoAlpha: 0,
    });
    gsap.to(logiciels, {
      x: 20,
      duration: 0.5,
      ease: 'power2.in',
      autoAlpha: 0,
    });
  }
}
