export default class LoadingScreen {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    window.addEventListener('load', () => {
      setTimeout(this.fadeOutLoader.bind(this), 3000);
    });
  }

  fadeOutLoader() {
    this.element.classList.add('fade-out');
    setTimeout(() => {
      this.element.style.display = 'none';
    }, 1000);
  }
}
