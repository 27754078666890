class HoverTextFollow {
  constructor(element) {
    this.gallery = element;
    this.hoverText = null;
    this.init();
  }

  init() {
    this.createHoverText();
    this.attachEvents();
  }

  createHoverText() {
    this.hoverText = document.createElement('div');
    this.hoverText.classList.add('hover-text-follow');
    document.body.appendChild(this.hoverText);
  }

  attachEvents() {
    const items = this.gallery.querySelectorAll('.gallery__item');

    items.forEach((item) => {
      item.addEventListener('mouseenter', (e) => this.showText(e, item));
      item.addEventListener('mouseleave', () => this.hideText());
      item.addEventListener('mousemove', (e) => this.moveText(e));
    });
  }

  showText(event, item) {
    const text = item.getAttribute('data-hover-text') || 'Loisir';
    this.hoverText.textContent = text;
    this.hoverText.classList.add('display');
    this.moveText(event);
  }

  hideText() {
    this.hoverText.classList.remove('display');
  }

  moveText(event) {
    const offset = 15;
    const x = event.clientX + offset;
    const y = event.clientY + offset;
    this.hoverText.style.left = `${x}px`;
    this.hoverText.style.top = `${y}px`;
  }
}

export default HoverTextFollow;
