export default class VideoPlayer {
  constructor(element) {
    this.element = element;
    this.video = element.querySelector('video');
    this.init();
  }

  init() {
    if (this.video) {
      console.log('VideoPlayer initialized');
      this.video.loop = true; // Enable looping
      this.setupIntersectionObserver();
      this.video.addEventListener('click', () => this.togglePlayPause());
    } else {
      console.error('No video element found');
    }
  }

  setupIntersectionObserver() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.playVideo();
        } else {
          this.pauseVideo();
        }
      });
    }, options);

    observer.observe(this.element);
  }

  playVideo() {
    if (this.video && typeof this.video.play === 'function') {
      this.video
        .play()
        .catch((error) => console.error('Error playing video:', error));
    } else {
      console.error('Play function not available');
    }
  }

  pauseVideo() {
    if (this.video && typeof this.video.pause === 'function') {
      this.video.pause();
    } else {
      console.error('Pause function not available');
    }
  }

  togglePlayPause() {
    if (this.video) {
      if (this.video.paused) {
        this.playVideo();
      } else {
        this.pauseVideo();
      }
    }
  }
}
