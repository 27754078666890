export default class LetterWave {
  constructor(element) {
    this.element = element;
    this.init();
    this.startPeriodicWave();
  }

  init() {
    const text = this.element.textContent;
    this.element.textContent = '';

    this.letters = [];

    text.split('').forEach((char, index) => {
      const span = document.createElement('span');
      span.classList.add('letter');
      span.textContent = char === ' ' ? '\u00A0' : char;
      span.style.animationDelay = `${index * 0.05}s`;
      this.element.appendChild(span);
      this.letters.push(span);
    });
  }

  startWave() {
    if (this.element.classList.contains('wave-active')) return;

    this.element.classList.add('wave-active');

    const lastLetter = this.letters[this.letters.length - 1];
    const animationDuration = 600;
    const computedStyle = window.getComputedStyle(lastLetter);
    const delay = parseFloat(computedStyle.animationDelay) * 1000;
    const totalTime = delay + animationDuration;

    setTimeout(() => {
      this.resetWave();
    }, totalTime);
  }

  resetWave() {
    this.element.classList.remove('wave-active');
  }

  startPeriodicWave() {
    this.startWave();

    this.intervalId = setInterval(() => {
      this.startWave();
    }, 5000);
  }

  destroy() {
    clearInterval(this.intervalId);
  }
}
